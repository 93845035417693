import React, { useEffect, useState, useMemo,useContext } from 'react';
// @ts-ignore
import { USBLink } from '@usb-shield/components-react';
import Loader from '../../components/utilities/Loader';
import moment from 'moment';
import { getRequirementsRequirements, getDefaultFilters } from './utils';
import RequirementsFilters from './RequirementsFilters';
import RequirementsTable from './RequirementsTable';
import Pagination from './Pagination';
import { USBIconDownload, USBIconPeople } from '@usb-shield/react-icons';
import './Requirements.scss';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import actionlogo from '../../assets/images/rightArrow.png';
import { FeatureFlagsContext } from '../../components/Hooks/useFeatureFlags';
import { getValueByNestedKey } from './utils';

function Requirements() {
  const [error, setError] = useState(null);
  const [status, setStatus] = useState({ loading: true, error: null });
  const [requirements, setRequirements] = useState(null);
  const [filters, setFilters] = useState(getDefaultFilters());
  const [opneModel, setOpenModel] = useState(false)
  const [requirementID, setRequirementId] = useState('');
  const [reqStatus, setReqStatus] = useState('');
  const [reqDealId,setReqDealId] = useState('');
  const { featureDetails } = useContext(FeatureFlagsContext);
  async function fetchData() {
    await getRequirementsRequirements({ setRequirements, setStatus ,reqStatus,reqDealId});
    await setStatus({ loading: false, error: status.error });
  }

  // useEffect(() => {
  //   fetchData();
  //   console.log(requirements)
  // }, []);

  useEffect(() => {
    fetchData();
    console.log(requirements)
  }, [reqDealId,reqStatus]);


  useEffect(() => {
    if(featureDetails?.length>0){
    if(!featureDetails?.includes("feature_requirements_visible")){
       window.location.href="/Redirect";
    }
}
}, [featureDetails])

  // const filteredRequirements = useMemo(() => {
  //   console.log(requirements)
  //   return [...requirements].sort((a, b) => {
  //     const valueA = getValueByNestedKey(a, "dueDate");
  //     const valueB = getValueByNestedKey(b, "dueDate");
  //     if (valueA < valueB) {
  //       return  -1 ;
  //     } else if (valueA > valueB) {
  //       return 1 ;
  //     }
  //     return 0;
  //   });
  // }, [filters, requirements]);

  const filteredRequirements = useMemo(() => {
    return requirements?.filter((item) =>
      Object.entries(filters).every(([key, obj]) => {
        const keys = key.split('.');
      return (
                    keys.reduce((acc, current) => acc && acc[current], item)?.substring(0, obj?.value?.length) === obj?.value || keys.reduce((acc, current) => acc && acc[current], item)?.substring(0, obj?.value?.length) === moment(obj?.value).format('MM/DD/YYYY')
        );
      })
    );
  }, [filters, requirements]);

  const columns = [
    {
      label: 'Due Date',
      accessor: 'dueDate',
      sortable: true,

      cell: (row) => {
        const date = new Date(row.dueDate);
        return <>{date.toLocaleString("en-US", {
          year: "numeric",
        month: "2-digit",
        day: "2-digit"
      })}</>;
      },
      style: { width: '15%' },
    },
    {
      label: 'Deal Id',
      accessor: 'dealId',
      sortable: true,
      style: { width: '10%' },
    },
    {
      label: 'Deal Name',
      accessor: 'dealName',
      sortable: true,
      style: { width: '20%' },
    },

    {
      label: 'Parent Company',
      accessor: 'parentCompanyName',
      sortable: true,
      style: { width: '20%' },
    },
    
    {
      label: 'Document Type',
      accessor: 'category',
      style: { width: '15%' },
      cell: (row) => {
        return <>{ row.category?.charAt(0).toUpperCase() + row.category?.slice(1)}</>;
      },
    },
    {
      label: 'Status',
      accessor: 'status',
      sortable: true,
      style: { width: '10%' },
    },
    

    {
      label: 'Open',
      accessor: '',
      cell: (row) => {
        return (
          <>
            <USBLink
              href={`RequirementDetails?id=${row.id}`}
              linkType="basic"
            >
               <div className="expand-column-off">
                <img src={actionlogo}></img>
              </div>
            </USBLink>
          </>
        );
      },
      style: { width: '10%' },
    },
  ];
  function onCancel(){
    setOpenModel(false)
}
const [currentPage, setCurrentPage] = useState(1);
const [requirementsPerPage, setRequirementsPerPage] = useState(10);

const paginate = pageNumber => setCurrentPage(pageNumber);

const indexOfLastRequirement = currentPage * requirementsPerPage;
const indexOfFirstRequirement = indexOfLastRequirement - requirementsPerPage;
const currentRequirements = requirements?.slice(indexOfFirstRequirement, indexOfLastRequirement);



const startIndex = (currentPage - 1) * requirementsPerPage;
const endIndex = startIndex + requirementsPerPage;
 

  return (
    <section className="requirements-portfolio-container width-100">


{featureDetails?.length>0 && featureDetails?.includes("feature_requirements_visible")?

      <section className="full-height">

         
       

      <USBBreadcrumb
            id="requirements"
            text="Requirements"
            breadcrumbs={[
              {
                id: 1,
                text: 'Tools',
              },
              {
                id: 2,
                text: 'All Requirements',
                href: '/Requirements',
              },
            ]}
          />


        <h1 className="heading-h1 p-t-md">All Requirements</h1>
        <p className="secondary-text">
          You'll see all requirements irrespective of assigned user. Please type full Deal ID in text box to filter. Click on Open icon to navigate to requirement.
        </p>
         {/* component for popup on screen  */}
       
        

        <RequirementsFilters
          requirements={requirements}
          filters={filters}
          setReqDealId={setReqDealId}
          setReqStatus={setReqStatus}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
          setRequirements={setRequirements}
          setStatus={setStatus}
        ></RequirementsFilters> 
{filteredRequirements?.length>10 && (<>
{/* Page number - {currentPage} */}
<Pagination
        requirementsPerPage={requirementsPerPage}
        totalRequirements={filteredRequirements?.length}
        paginate={paginate} currentPage={currentPage}
      />
</>)}
      <RequirementsTable
          data={filteredRequirements?.sort((a, b) => {
            const valueA = getValueByNestedKey(a, "dueDate");
            const valueB = getValueByNestedKey(b, "dueDate");
            if (valueA < valueB) {
              return  1 ;
            } else if (valueA > valueB) {
              return -1 ;
            }
            return 0;
          }).slice(startIndex, endIndex) || []}
          headers={columns}
          status={status}
          setStatus={setStatus}
          searchProperties={{
            enable: true,
            keys: [
              'dueDate',
              'dealId',
              'dealName',
              'parentCompanyName',
              'category',
              'status',
            ],
          }}
        ></RequirementsTable>  
        {/* page loader */}
        {/* @ts-ignore */}
        {/* {status.loading ? <Loader /> : null} */}
      </section>
      :''}
    </section>
  );
}

export default Requirements;
