import axios from 'axios';
import { endpoints } from '../../../config/connections';

export async function getRequirementsRequirements({ setRequirements, setStatus,reqStatus,reqDealId }) {
  const url = `${endpoints.apigee}/document-management/cdc/requirements/v1/employee/requirements?status=${reqStatus}&dealId=${reqDealId}`;
  const https = getAxiosInstance();
  const response = await https.get(url);
  if (response?.status === 200) {
    setRequirements(response?.data);
  } else {
    setStatus((state) => ({
      ...state,
      loading: false,
      error: 'Failed to get Requirements requirements.',
    }));
  }
}



export function getAxiosInstance() {
  const loggedInUser = sessionStorage.getItem('loggedUser');
  const correlationId = sessionStorage.getItem('correlationId');
  const at = sessionStorage.getItem('at');
  const jwt = sessionStorage.getItem('tok');
  const userRole = sessionStorage.getItem('userrole');
  const assignedUser = sessionStorage.getItem('assignedUser');
  const remoteUser = sessionStorage.getItem('remote_user');

  return axios.create({
    headers: {
      Accept: 'application/json',
      'Correlation-ID': `${loggedInUser} ${correlationId}`,
      'Client-Application': 'CDC',
      Authorization: `Bearer ${at}`,
      jwt: `${jwt}`,
      'user-id': remoteUser ? JSON.parse(remoteUser).userID : `${assignedUser}`,
    },
  });
}

// Constants

export function isEmployee() {
  const userRole = sessionStorage.getItem('userrole');
  return userRole === 'employee';
}

export function getDefaultFilters() {
   return { status: { label: '', value: '' } };
}

// returns value in key by checking the obj recursively and if it encounters an array of objects then it
// return first element and parses in that
export function getValueByNestedKey(obj, key) {
  const keys = key.split('.');
  return keys.reduce((nested, nestedKey) => {
    if (Array.isArray(nested?.[nestedKey])) {
      return nested && nested[nestedKey]?.[0];
    }
    return nested && nested[nestedKey];
  }, obj);
}
