import React from 'react';
import './pagination.css';

const Pagination = ({ requirementsPerPage, totalRequirements, paginate, currentPage }) => {
  let pageNumbers = [];

//   for (let i = 1; i <= Math.ceil(totalRequirements / requirementsPerPage); i++) {
//     pageNumbers.push(i);
// }
// for (let i = currentPage; i <= Math.ceil(totalRequirements / requirementsPerPage); i++) {
//         pageNumbers.push(i);
//     }
const tempPageNumbers=[]
for (let i = currentPage; i <= (currentPage+3);  i++) {
    if(i>2){
        tempPageNumbers.push(i-2);
    }
    if(i < Math.ceil(totalRequirements / requirementsPerPage)-3){
    tempPageNumbers.push(i + 1);
    }
  }


// Convert the Set back to an array
 pageNumbers = [...new Set(tempPageNumbers)].sort(function(a, b) {
    return a - b;
  });

  var predicate = (x) => x > Math.ceil(totalRequirements / requirementsPerPage);
var output = pageNumbers.filter(predicate);
pageNumbers = pageNumbers.filter(x => !predicate(x));

//   alert(Math.ceil(totalRequirements / requirementsPerPage))

  

  return (
    <nav>
      <ul className='pagination'>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a onClick={() => paginate(1)}  className='page-link'>
            First
          </a>
        </li>
        {pageNumbers.map(number => (
          <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
            <a onClick={() => paginate(number)} className='page-link'>
              {number}
            </a>
          </li>
        ))}
        <li className={`page-item ${currentPage === Math.ceil(totalRequirements / requirementsPerPage) ? 'disabled' : ''}`}>
          <a onClick={() => paginate(Math.ceil(totalRequirements / requirementsPerPage))}  className='page-link'>
            Last
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;